<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="card card-custom gutter-b example example-compact"
          v-if="filter"
        >
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-lg-10 col-xl-8">
                <div class="row align-items-center pt-5 pb-5">
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="reg_number__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Raqam"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="exported_warehouse__name__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Chiqim skladi"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="placement_warehouse__name__icontains"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Kirim skaldi"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="storedproductservice__total_withnds_summa"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Summa (NDS)"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="storedproductservice__nds_summa"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="NDS"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="storedproductservice__total"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Summa"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- <label class="mr-3 mb-0 d-none d-md-block">Type:</label> -->
                      <el-date-picker
                        v-model="oper_date"
                        type="date"
                        @change="operDate"
                        format="yyyy-MM-dd"
                        placeholder="Sana"
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-xl-4 mt-5 mt-lg-0 text-right">
                <v-btn small @click="reset" color="error">{{
                  $t('BREADCRUMBS.CLEAN')
                }}</v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.STORE.TRANSFER') }}
              </h3>
            </div>

            <div class="card-button">
              <v-btn color="warning mx-2" @click="filter = !filter">
                {{ $t('BREADCRUMBS.SEARCH') }}

                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn to="/transfercreate" class="ml-2" color="primary"
                >{{ $t('MENU.STORE.TRANSFER') }} +</v-btn
              >
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import ContractsAction from "@/view/content/dropdown/ContractsAction.vue";

export default {
  data() {
    return {
      filter: false,
      placement_warehouse__name__icontains: '',
      exported_warehouse__name__icontains: '',
      reg_number__icontains: '',
      storedproductservice__total_withnds_summa: '',
      storedproductservice__nds_summa: '',
      storedproductservice__total: '',
      oper_date: '',
      payTypes: [
        { name: 'Muddatli', value: 'MU' },
        { name: 'Muddati cheklanmagan', value: 'MS' },
        { name: "Summaga bog'liq", value: 'SU' }
      ],
      types: [
        { name: 'Kelishuv', value: 'NAQD' },
        { name: 'Shartnoma', value: 'CONT' }
      ]
    }
  },
  props: {
    items: Object
  },
  created() {
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
        this.$store.state.requests.filterData.path.substring(
          0,
          this.$route.path.lastIndexOf('/')
        ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.filter = true
        this.storedproductservice__total_withnds_summa =
          data.storedproductservice__total_withnds_summa
        this.storedproductservice__nds_summa =
          data.storedproductservice__nds_summa
        this.storedproductservice__total = data.storedproductservice__total
        this.placement_warehouse__name__icontains =
          data.placement_warehouse__name__icontains
        this.exported_warehouse__name__icontains =
          data.exported_warehouse__name__icontains
        this.reg_number__icontains = data.reg_number__icontains
        this.oper_date = data.oper_date
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  methods: {
    operDate(d) {
      if (d !== null) {
        let currentDate = new Date(d)
        let y = currentDate.getFullYear()
        let m = currentDate.getMonth() + 1
        let day = currentDate.getDate()
        this.oper_date = `${y}-${m}-${day}`
        this.myFn()
      } else {
        this.myFn()
        this.oper_date = ''
      }
    },
    reset() {
      this.placement_warehouse__name__icontains = ''
      this.exported_warehouse__name__icontains = ''
      this.reg_number__icontains = ''
      this.storedproductservice__total_withnds_summa = ''
      this.storedproductservice__nds_summa = ''
      this.storedproductservice__total = ''
      this.oper_date = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      this.myFn()
    },
    myFn() {
      const data = {}
      if (this.oper_date !== '') {
        if (this.oper_date !== null) {
          data.oper_date = this.oper_date
        }
      }
      if (this.reg_number__icontains !== '') {
        data.reg_number__icontains = this.reg_number__icontains
      }
      if (this.exported_warehouse__name__icontains !== '') {
        data.exported_warehouse__name__icontains =
          this.exported_warehouse__name__icontains
      }
      if (this.placement_warehouse__name__icontains !== '') {
        data.placement_warehouse__name__icontains =
          this.placement_warehouse__name__icontains
      }

      if (this.storedproductservice__total_withnds_summa !== '') {
        data.storedproductservice__total_withnds_summa =
          this.storedproductservice__total_withnds_summa
      }
      if (this.storedproductservice__nds_summa !== '') {
        data.storedproductservice__nds_summa =
          this.storedproductservice__nds_summa
      }
      if (this.storedproductservice__total !== '') {
        data.storedproductservice__total = this.storedproductservice__total
      }

      this.$store.commit('setFilterData', {
        data: data,
        path: this.$route.path
      })
      if (Object.keys(data).length > 0) {
        data.operation_type = 'TRANSFER'
        this.$store.dispatch('transferSearch', { page: 1, payload: data })
      } else {
        this.$store.dispatch('getGoodsServicesImport', {
          operation_type: 'TRANSFER',
          page: 1
        })
      }
      if (this.$route.path !== '/transferpoducts/1') {
        this.$router.push('/transferpoducts/' + '1')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.STORE.TITLE') },
      { title: this.$t('MENU.STORE.TRANSFER') }
    ])
  }
}
</script>
<style>
@media (min-width: 992px) {
  .content {
    padding: 0 !important;
  }
}
</style>
